import "./style.css";
import React, { useState, useRef, useEffect } from "react";
import { ClickAwayListener, ListSubheader, MenuItem, Paper, Popper } from "@mui/material";
import { ArrowRight, InsertEmoticonSharp } from "@mui/icons-material";
import MDTypography from "components/Basics/MDTypography";
import MDBox from "components/Basics/MDBox";

const unstyledStyle = {
	fontFamily: "Arial",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	padding: "10px",
	cursor: "pointer",
	border: "1px solid #cccccc55",
	height: "40px",
	backgroundColor: "white"
};

const DictionaryMenu = ({
	unstyled = false,
	levelLayer = false,
	dictionary,
	anchorEl,
	handleClose,
	handleInsertText,
	placement = "bottom",
	restrictedTypes = null, // Array of types that are restricted
	clickableLevels = true
}) => {
	// Keep menu open and close on hover
	const [resetLayer, setResetLayer] = useState({
		layer: 0,
		ref: null
	});

	return (
		<Popper
			id={anchorEl ? "simple-popper" : ""}
			open={Boolean(anchorEl)}
			anchorEl={anchorEl}
			placement={placement}
			style={{
				zIndex: 10000
			}}
		>
			<ClickAwayListener onClickAway={handleClose}>
				<Paper className="paperDictMenu">
					<MDBox
						bgColor="white"
						borderRadius="md"
						style={{
							boxShadow: "0rem 0 0.8rem 0.4rem rgba(0, 0, 0, 0.1)"
						}}
						p={0.5}
					>
						{Object.keys(dictionary).map((key, index) => {
							const item = dictionary[key];

							if (item.source === "title") {
								return (
									<MenuItem disabled sx={{ pl: 0.5 }} style={{ opacity: 1 }} key={index}>
										<MDTypography variant="caption" style={{ fontWeight: "bold" }}>
											{item.label}
										</MDTypography>
									</MenuItem>
								);
							}

							return (
								<MyMenuItem
									key={index}
									unstyled={unstyled}
									path={key}
									item={item}
									restrictedTypes={restrictedTypes}
									handleAction={path => {
										handleInsertText(path);
									}}
									levelLayer={levelLayer}
									resetLayer={resetLayer}
									setResetLayer={setResetLayer}
									clickableLevels={clickableLevels}
								/>
							);
						})}
					</MDBox>
				</Paper>
			</ClickAwayListener>
		</Popper>
	);
};

const SingleLevel = ({
	item,
	restrictedTypes,
	unstyled,
	path,
	handleAction,
	layer,
	resetLayer,
	setResetLayer
}) => {
	const [isHover, setIsHover] = useState(false);

	let openStyle = isHover ? { backgroundColor: "#eeeeee" } : {};
	const openRef = useRef(null);

	const handleMouseEnter = () => {
		setIsHover(true);
		setResetLayer({
			layer,
			ref: openRef.current
		});
	};

	const disabled = restrictedTypes ? !restrictedTypes.includes(item.type) : false;

	return (
		<MDBox
			p={1}
			ref={openRef}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			onMouseEnter={handleMouseEnter}
			onClick={() => {
				if (disabled) return;
				handleAction(path);
			}}
			onMouseLeave={() => setIsHover(false)}
			className={`dictionary-menu-single-level ${disabled ? "" : "selection"}`}
			style={unstyled ? { ...unstyledStyle, ...openStyle } : {}}
			borderRadius="md"
		>
			<MDTypography
				variant="button"
				style={{
					color: disabled ? "#cccccc" : "inherit"
				}}
			>
				{item.label?.fr}
			</MDTypography>
		</MDBox>
	);
};

const MultiLevel = ({
	unstyled,
	item,
	restrictedTypes,
	path,
	handleAction,
	levelLayer,
	layer,
	resetLayer,
	setResetLayer,
	clickableLevels
}) => {
	const children = item.items;
	const hasChildrenLevel =
		Object.keys(children).find(key => children[key].type === "level") ?? false;
	const [open, setOpen] = useState(false);
	const openRef = useRef(null);

	const disabled = restrictedTypes ? !restrictedTypes.includes(item.type) : false;

	let openStyle = open ? { backgroundColor: "#eeeeee" } : {};

	const handleMouseEnter = () => {
		setOpen(true);
		setResetLayer({
			layer,
			ref: openRef.current
		});
	};

	useEffect(() => {
		if (layer > resetLayer.layer) {
			setOpen(false);
		}

		if (layer === resetLayer.layer && resetLayer.ref !== openRef.current) {
			setOpen(false);
		}
	}, [resetLayer]);

	return (
		<MDBox>
			<MDBox
				p={1}
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				ref={openRef}
				className={`dictionary-menu-multi-level ${open ? "selection subMenuOpen" : "selection"}`}
				onMouseEnter={handleMouseEnter}
				style={unstyled ? { ...unstyledStyle, ...openStyle } : {}}
				borderRadius="md"
				onClick={() => {
					if (disabled || !clickableLevels) return;
					handleAction(path);
				}}
			>
				<MDTypography variant="button">{item.label?.fr}</MDTypography>
				{(!levelLayer || (levelLayer && hasChildrenLevel)) && (
					<ArrowRight height={unstyled ? 40 : null} />
				)}
			</MDBox>
			<Popper
				open={open}
				anchorEl={openRef.current}
				placement="right"
				style={{
					zIndex: 10000
				}}
			>
				<Paper
					className="paperDictMenu"
					style={{
						zIndex: 10000
					}}
				>
					<MDBox
						bgColor="white"
						borderRadius="md"
						style={{
							boxShadow: "0rem 0 0.8rem 0.4rem rgba(0, 0, 0, 0.1)"
						}}
						p={0.5}
					>
						{Object.entries(children).map(([key, child]) => (
							<MyMenuItem
								unstyled={unstyled}
								key={key}
								item={child}
								restrictedTypes={restrictedTypes}
								path={`${path}.${key}`}
								handleAction={path => handleAction(path)}
								levelLayer={levelLayer}
								layer={layer}
								resetLayer={resetLayer}
								setResetLayer={setResetLayer}
								clickableLevels={clickableLevels}
							/>
						))}
					</MDBox>
				</Paper>
			</Popper>
		</MDBox>
	);
};

const MyMenuItem = ({
	unstyled,
	item,
	restrictedTypes,
	path,
	handleAction,
	levelLayer,
	layer = 0,
	resetLayer,
	setResetLayer,
	clickableLevels
}) => {
	const Component = item.items && Object.keys(item.items).length > 0 ? MultiLevel : SingleLevel;
	return (
		<Component
			style={{ zIndex: 1 }}
			unstyled={unstyled}
			path={path}
			item={item}
			restrictedTypes={restrictedTypes}
			handleAction={handleAction}
			levelLayer={levelLayer}
			layer={layer + 1}
			resetLayer={resetLayer}
			setResetLayer={setResetLayer}
			clickableLevels={clickableLevels}
		/>
	);
};

export default DictionaryMenu;
